import axios from 'axios'

/**
 * Submit secure post
 * @param url
 * @param data
 * @returns {Promise<T>}
 */
export const $post = async (url, data) => {
  return await axios({
    method: 'POST',
    url: url,
    data: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'text/plain; charset=utf-8',
      // Authorization: `Bearer ${token}`,
    },
    withCredentials: false,
  })
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error.response)
    })
}

/**
 * Submit Unsecure post
 * @param url
 * @param data
 * @param _secure
 * @returns {Promise<T>}
 */
export const $unsecurePost = async (url, data, _secure = false) => {
  return await axios({
    method: 'POST',
    url: url,
    data: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    },
    withCredentials: false,
  })
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

/**
 * Submit Get data
 * @param url
 * @returns {Promise<T>}
 */
export const $get = async (url) => {
  return await axios({
    url: url,
    method: 'GET',
  })
    .then((response) => {
      return Promise.resolve(response?.data)
    })
    .catch((error) => {
      return Promise.reject({ ...error })
    })
}

export const $inlineDownload = async (url) => {
	return await axios({
    url: url,
    method: 'GET',
    responseType: "blob" ,
    withCredentials: false,
  })
    .then((response) => {
    	
    	let filename='Untitled.txt'
    	const disposition=response.headers['content-disposition']
    	if (disposition && disposition.indexOf('inline') !== -1) {
    		const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    		const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
    	}
    	
    	let filetype='text-plain'
    	if (response.headers['content-type']) {
    		filetype=response.headers['content-type']
    	}
    	
    	return Promise.resolve([response.data,filename,filetype])
    })
    .catch((error) => {
      return Promise.reject({ ...error })
    })
}
