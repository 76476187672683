import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Icon from './Icon'

const Modal = ({ title = '', text = '', btnIcon = '', trigger = '', bundle = '', device = '', identifier='', callback, dialog = false, confirm = false }) => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()
  const handleShowModal = () => {
    setShowModal(!showModal)
  }
  const handleYesClick = ()=>{
    callback();
    setShowModal(false);
  }
  return (
    <>
      <div className="modalBtn" onClick={handleShowModal}>
        {trigger === '' ? <Icon icon={btnIcon} /> : trigger}
      </div>
      {showModal && (
        <div className="modal-wrap">
          <div
            className="background"
            onClick={() => {
              setShowModal(false)
            }}
          ></div>

          <div className="modal-alert">
            <div className="modal-top">
              <div className="title">{title}</div>
              <div
                className="x-icon"
                onClick={() => {
                  setShowModal(false)
                }}
              >
                <Icon icon={'x'} />
              </div>
            </div>
            <div className="modal-body">
              <div>
                <span>{text}</span>
                <span className="bold "> {bundle} </span>
                <span>{t('devices.from')} </span>
                <div className="bold device">{device}</div>
                
                <div>
                {identifier.length==17 && (
                  	<>
                		<br/><span>{t('devices.mac_address')}</span><span className="device-id">{identifier}</span>
                	</>
                )}
               
               {identifier.length>17 && (
                  	<>
                	 	<br/><span>{t('devices.computer_uuid')}</span><span className="device-id">{identifier}</span>
                	</>
                )}
                </div>
              </div>
              <div className="are-you-sure">{t('devices.are_you_sure')}</div>
              <div className="btns-row">
                {dialog && (
                  <>
                    <div className="accept">
                      <button className="btn-md me-3 btn btn-success" onClick={handleYesClick}>
                        {t('global.yes')}
                      </button>
                    </div>
                    <div className="decline">
                      <button
                        className="btn-md btn btn-outline-secondary"
                        onClick={() => {
                          setShowModal(false)
                        }}
                      >
                        {t('global.no')}
                      </button>
                    </div>
                  </>
                )}
                {confirm && (
                  <>
                    <div className="confirm">
                      <button
                        className="btn-md btn btn-outline-secondary"
                        onClick={() => {
                          setShowModal(false)
                        }}
                      >
                        {t('global.ok')}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Modal
