import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import TitleHolder from 'components/layouts/TitleHolder'
import ContentHolder from 'components/layouts/ContentHolder'
import ToastPopUp from 'components/layouts/toast'
import Modal from 'components/layouts/modal'
import useApiCall from 'components/api/useApiCall'
import Skeleton from 'react-loading-skeleton'
import Nodevices from 'assets/media/img/devices_tower.webp'
import NotFound from '../Layout/notFound'
import { LoadingContext } from 'contexts/loading'
import { $unsecurePost } from 'components/hooks/useAxios'
import { ErrContext } from 'contexts/errorContext'
import toastConfig from 'configs/toastConfig'
import { useHistory } from 'react-router-dom'
import { config } from 'configs/constants'
import { $inlineDownload } from 'components/hooks/useAxios'
import Icon from 'components/layouts/Icon'
import { saveAs } from 'file-saver'

const Devices = () => {
  const { t } = useTranslation()
  let [{ error, isLoaded, data }] = useApiCall('devices')
  const [deviceName, setDeviceName] = useState('')
  const [deviceHardwareIdentifier, setDeviceHardwareIdentifier] = useState('')
  const [bundleName, setBundleName] = useState('')
  const [loading, setLoading] = useContext(LoadingContext)
  const [err, setErr] = useContext(ErrContext)
  const history = useHistory()
  const [modifiedData, setModifiedData] = useState()
  let headers = [
    { name: 'devices.device_title', className: 'device-name-header' },
    { name: 'devices.serial_number_title', className: 'serial-number-header' },
    { name: 'devices.seats', className: 'seats-header' },
    { name: 'devices.installer', className: 'installer-header' },
    { name: 'devices.deauthorize', className: 'deauthorize-header' },
  ]
  const handleDeauthorize = (e, computer, license) => {
    // e.stopPropagation()
    setLoading(true)
    let url = `${config.urls.ACCOUNT_URL}/api/user/deauthorize-device?deviceId=${computer.identifier}&api_token=${window.localStorage.h}&license=${license.serialNumber}`
    $unsecurePost(url).then(
      (result) => {
        setLoading(false)

        toastConfig.fire({
          icon: 'success',
          title: t('devices.removed'),
        })
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      },
      (error) => {
        setLoading(false)
        setErr(true)
        toastConfig.fire({
          icon: 'error',
          title: t('general.err'),
        })
      }
    )
  }
  useEffect(() => {
    if (isLoaded) {
      let tempData = { ...data }
      tempData?.message?.devices?.map((computer) => {
        computer.licenses.map((license) => {
          if (license.seatsUsed < 0) {
            license.seatsUsed = 0
          }
        })
      })
      setModifiedData(tempData)
    }
  }, [isLoaded])
  const handleDownload = (url) => {
    window.location = url
  }
  const exportDevices = (e) => { 
      try {      
       	setLoading(true)
      	let url = `${config.urls.ACCOUNT_URL}/api/user/export-devices?api_token=${localStorage.h}&all`
     	 $inlineDownload(url).then( 
     	 	(results) => {
          		setLoading(false)
          		const url = window.URL.createObjectURL(new Blob([results[0]]))
        		let blob = new Blob([results[0]], {type: "text/plain;charset=utf-8"})
        		saveAs(blob, results[1])
        		window.URL.revokeObjectURL(url);
        },
        (error) => {
          	setLoading(false)
          	setErr(true)
        	toastConfig.fire({
          	icon: 'error',
          	title: t('general.err'),
       	 	})
        }
      )
    } catch (e) {}  	
  }
  if ((isLoaded && !data?.message?.devices.length) || (isLoaded && error)) {
    return (
      <>
        <ContentHolder>
          <TitleHolder text={t('devices.title')} icon="devices" back={'/'} pageTitle={true} />
          <NotFound img={Nodevices} text={t('devices.no_devices')} supportText={t(`devices.register_support`)} />
        </ContentHolder>
      </>
    )
  }

  return (
    <ContentHolder>
      <>
        <TitleHolder text={t('devices.title')} icon="devices" back={'/'} pageTitle={true} />
        
        <div className="page--devices">
                  
          <div className="mobile-wrapper">
            {!isLoaded && data && (
              <>
              	<div className="export">
                 	 <span onClick={() => exportDevices()} className="active">
                      	<Icon icon={'exportDevices'}></Icon>
                    </span>
                  	</div>
                <div className="card-wrapper-row ">
                  <Skeleton count={10} className="invoices-skel-mobile" />
                </div>
              </>
            )}

            {isLoaded &&
              data &&
              modifiedData?.message?.devices.map((computer, index) => (
                <div className="card-wrapper-row col-12" key={index}>
                  <div className="device-name block">
                    <div className="device-name-title sub-label">{t('devices.device_title')}</div>
                    <div className="label">{computer.name.replace('&#8217;', `'`)}</div>
                  </div>
                  <div className="serial-number-title sub-label">{t('devices.serial_number_title')}</div>
                  {computer?.licenses.map((license, index) => (
                    <div className="product block" key={index}>
                      <div className="label">{license.product}</div>
                      <div className="serial-number-text">{license.serialNumber}</div>
                      <div className="actions-wrap">
                        <div className="seats">
                          <div className="seats-title sub-label">{t('devices.seats')}</div>
                          <div className="seats-number">
                            {license.seatsUsed > license.seats ? license.seats : license.seatsUsed}/{license.seats}
                          </div>
                        </div>
                        <div className="installer">
                          <div className="seats-title sub-label mb-1">{t('devices.installer')}</div>
                          <div className="installer-icon">
                            <ToastPopUp
                              btnIcon={'downloadInvoice'}
                              toastIcon={'success'}
                              title={t('devices.start_download')}
                              callback={() => handleDownload(license.downloadURL)}
                              dialog={true}
                            ></ToastPopUp>
                          </div>
                        </div>
                        <div className="deauthorize">
                          <div className="seats-title sub-label mb-1">{t('devices.deauthorize')}</div>
                          <div
                            className="trash-icon"
                            onClick={() => {
                              setDeviceName(computer.name.replace('&#8217;', `'`))
                              setDeviceHardwareIdentifier(computer.identifier!=computer.name?computer.identifier:'')
                              setBundleName(license.product)
                            }}
                          >
                            <Modal
                              device={deviceName}
                              bundle={bundleName}
                              identifier={deviceHardwareIdentifier}
                              btnIcon={'trash'}
                              title={t('devices.deauthorize_device')}
                              text={t('devices.about_to_remove')}
                              callback={(e) => handleDeauthorize(e, computer, license)}
                              dialog={true}
                            ></Modal>
                          </div>
                        </div>
                      </div>
                      <div className="label">
                        {computer.identifier.length==17 && (<span>{t('devices.mac_address')}{computer.identifier}</span>)}
               			{computer.identifier.length>17 && (<span>{t('devices.computer_uuid')}{computer.identifier}</span>)}
                    </div>
                    </div>
                  ))}
                </div>
              ))}
          </div>

          <div className="desktop-wrapper">
            {data?.message?.devices.length > 0 && isLoaded && (
              <>
                <div className="card-wrapper-row ">
                	<div className="export">
                 	 	<span onClick={() => exportDevices()} className="active">
                      		<Icon icon={'exportDevices'}></Icon>
                    	</span>
                  	</div>
                  <div className="table-top col-11">
                    {headers.map((header, k) => (
                      <div key={k} className={`table-header ${header.className}`}>
                        {t(header.name)}
                      </div>
                    ))}
                  </div>
                  <div className="products-wrap">
                    {modifiedData?.message?.devices.map((computer, index) => (
                    	<div>
                      		<div className="product" key={index}>
                      			<div className="device-name label item">{computer.name.replace('&#8217;', `'`)}</div>
                        		<div className="plans">
                          {computer?.licenses.map((license, index) => (
                            <div className="product-wrap" key={index}>
                              <div className="products item">
                                <div className="keys">
                                  <div className="product-name label">{license.product}</div>
                                  <div className="key">{license.serialNumber}</div>
                                </div>
                              </div>
                              <div className="seats item">
                                {license.seatsUsed > license.seats ? license.seats : license.seatsUsed}/{license.seats}
                              </div>
                              <div className="installer item">
                                <div>
                                  <ToastPopUp
                                    trigger={t('devices.download')}
                                    toastIcon={'success'}
                                    title={t('devices.start_download')}
                                    callback={() => handleDownload(license.downloadURL)}
                                  ></ToastPopUp>
                                </div>
                              </div>
                              <div
                                className="deauthorize item"
                                onClick={() => {
                                  setDeviceName(computer.name.replace('&#8217;', `'`))
                                  setDeviceHardwareIdentifier(computer.identifier!=computer.name?computer.identifier:'')
                                  setBundleName(license.product)
                                }}
                              >
                                <div>
                                  <Modal
                                    device={deviceName}
                                    bundle={bundleName}
                                    identifier={deviceHardwareIdentifier}
                                    trigger={t('devices.remove')}
                                    title={t('devices.deauthorize_device')}
                                    text={t('devices.about_to_remove')}
                                    callback={(e) => handleDeauthorize(e, computer, license)}
                                    dialog={true}
                                  ></Modal>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        </div>
                        	<div className="device-identifier">
                        		{computer.identifier.length==17 && (<span>{t('devices.mac_address')}</span>)}
               					{computer.identifier.length>17 && (<span>{t('devices.computer_uuid')}</span>)}
                        		<span className="device-id">{computer.identifier}</span>
                        	</div>
                      </div>                  
                    ))}
                  </div>
                </div>
              </>
            )}

            {!isLoaded && data && (
              <div className="card-wrapper-row">
                <div className="product d-block">
                  <Skeleton count={5} className="skel-device" />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </ContentHolder>
  )
}

export default Devices
